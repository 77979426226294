@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-vendor: 204, 7, 30;
  }
}

.btn {
  @apply hover:scale-[1.02];
  @apply active:scale-[0.98];
  @apply duration-100;
  @apply cursor-pointer;
  @apply select-none;
}

.btn.small {
  @apply hover:scale-[1.04];
  @apply active:scale-[0.96];
}

.text {
  @apply text-gray-800;
}
