@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    height: 100vh;
    height: -webkit-fill-available;
  }
  body {
    @apply bg-gray-200;
  }
}

@layer utilities {
  .h-screen {
    height: 100vh;
    height: -webkit-fill-available;
  }
}
